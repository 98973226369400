import React, { useRef } from 'react';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import NavBar from './components/NavBar';
import ContactForm from './components/ContactForm';
import ProjectCard from './components/ProjectCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Typewriter } from 'react-simple-typewriter'; // Import the typewriter package
import './App.css';



function App() {
  const sliderRef = React.useRef(null);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IconButton
        className={className}
        onClick={() => sliderRef.current.slickNext()}
      >
        <ArrowForwardIos />
      </IconButton>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <IconButton
        className={className}
        onClick={() => sliderRef.current.slickPrev()}
      >
        <ArrowBackIos />
      </IconButton>
    );
  }
  
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: dots => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <SamplePrevArrow />
        <ul style={{ margin: "5px", padding: "0" }}> {dots} </ul>
        <SampleNextArrow />
      </div>
    ),
  };

  return (
    <Container>
      <NavBar />
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3">
          <Typewriter
              words={['Hi! I\'m Ethan']}
              loop={1}
              cursor={true}
              cursorStyle='|'
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </Typography>
          <Typography paragraph style={{fontSize: '1.3rem', textAlign: 'justify'}} className='core-text'>
          I am a passionate problem-solver and an aspiring cybersecurity expert. I have completed a Bachelor of Advanced Computing (Hons) at The University of Sydney, majoring in Computer Science and Cybersecurity.
          <br /><br />
          I've had the opportunity to work with some incredible teams, including as a Security Engineering Intern at Google and a Technical Intern at Cisco, where I gained hands-on experience in threat intelligence, incident response, and network security. While i am most comfortable working in Python, I have familiarity with Java, JavaScript, Bash, and more, and I enjoy diving into new challenges and technologies.
          <br /><br />

          Beyond my professional interests, I'm an enthusiastic Formula 1 fan, love playing board and video games, and often participate in Capture The Flag competitions to sharpen my skills.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src="intro_me.jpg" alt="Ethan Katte" style={{ width: '100%', borderRadius: '64px', marginTop: '60px' }} className='core-image'/>
        </Grid>
      </Grid>

      <Typography variant="h4" style={{ marginTop: '60px' }} className='experience-text'>Experience</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <ProjectCard
            title="Google - Security Engineer Intern"
            description=""
            image="experience_google.png"
            link="https://www.linkedin.com/feed/update/urn:li:activity:7165859179180572672/"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ProjectCard
            title="Cisco - Technical Intern"
            description=""
            image="experience_cisco.png"
            link="https://www.linkedin.com/posts/ethan-katte-261623151_internship2023-cisco-activity-7082991685013549056-9IVV/?utm_source=share&utm_medium=member_desktop"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ProjectCard
            title="Honours Student - The University of Sydney"
            description=""
            image="experience_usyd.png"
            link="https://scslab.net/our-team/ethan-katte/"
          />
        </Grid>
      </Grid>

      <Typography variant="h4" style={{ marginTop: '60px'}}>Projects</Typography>
      <div style={{padding: '15px'}}>
        <Slider ref={sliderRef} {...settings}>
          <div>
            <ProjectCard
              title="Network Topology Analysis and Mapping Tool"
              description="This tool is a part of the USYD Capstone project and allows users to vendor-agnostically view and track changes in network topology. Built using Python, React and Tailwind this leverages network discovery protocols and existing analysis tools (like NMap) to provide a comprehensive analysis. Containerized using Docker with the capacity for an externally hosted PostgreSQL database results in a distributable and simple format. "
              image="projects_topology_viewer.png"
              github="https://github.com/USYD-Capstone-2023/usyd51"
              file="https://www.linkedin.com/pulse/capstone-2023-network-mapping-ug-cian-byrne-e6zwc/?trackingId=DxsCKf%2BNTfGd5iBZwJNnuw%3D%3D"
            />
          </div>
          <div>
            <ProjectCard
              title="Thesis Research - Social Network Provenance"
              description="This research explores how image metadata is manipulated when being uploaded onto social media platforms. Novel methodologies are explored to identify image provenance (what platforms an image has been on) exclusively using the metadata alterations. The research has been submitted to UBICOMP/ICWS 2024, ICSOC 2024, PERCOM 2025 and The Web Conference 2025. Papers are available upon request."
              image="projects_honours.jpg"
            />
          </div>
          <div>
            <ProjectCard
              title="Incident Playground"
              description="This internal tool was developed as part of the Google Security Engineering internship. The task was to develop an incident management practice tool, utilising Artificial Intelligence to critique responders descisions. This tool integrated seamlessly with VertexAI, internal version control systems and allowed for dynamic scenario development. "
              image="projects_google.png"
            />
          </div>
          <div>
            <ProjectCard
              title="This Website!"
              description="This website provide up-to-date details of my experience and relevant projects. Built using React and Material UI, the website provides an efficient and cross-device compatible experience. Github Pages is used for the website hosting due to the static nature and desire for high availability."
              image="projects_website.png"
              github="https://github.com/EthanKatte/PersonalWebsiteV2"
            />
          </div>
          <div>
            <ProjectCard
              title="Notion Python API"
              description="An integration between the Notion notetaking ecosystem and python to allow for background updates of data built using the Requests and JSON libraries."
              image="projects_notion.png"
              github="https://github.com/EthanKatte/VeryUnofficialPythonNotionApi"
            />
          </div>
          <div>
            <ProjectCard
              title="Reselling Toolkit"
              description="This toolkit provides a suite of functionalities to check the current ebay prices of items available on online stores. The ResellerToolkit is built using python and the Selenium, bs4, and Multiprocessing Libraries."
              github="https://github.com/EthanKatte/ResellerToolkit"
            />
          </div>
          <div>
            <ProjectCard
              title="David Jones Lego Scraper"
              description="A program that checks the retired status of LEGO items available on the David Jones website created using the Requests-html, Webbrowser, and Multiprocessing libraries."
              github="https://github.com/EthanKatte/DJLegoScraper"
            />
          </div>
        </Slider>
      </div>

      <ContactForm />

      
    </Container>
  );
}

export default App;
